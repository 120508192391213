import React, { FunctionComponent, useState } from 'react';
import AdminLayout from '../layouts/admin-index';
import UserList from '../components/UserList';
import UserForm from '../components/UserForm';

const Index: FunctionComponent = () => {
  const [userListVersion, setUserListVersion] = useState(1);

  const incrementUserListVersion = () => {
    setUserListVersion(userListVersion + 1);
  };

  return (
    <AdminLayout>
      <h1 className="title">Gebruikers</h1>
      <div className="users-container">
        <div className="card">
          <div className="card-header">
            <p className="card-header-title">Voeg gebruiker toe:</p>
          </div>
          <div className="card-content">
            <div className="content">
              <div className="user-item">
                <UserForm callback={incrementUserListVersion} formUser={null} />
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <p className="card-header-title">Pas gebruikers aan:</p>
          </div>
          <div className="card-content">
            <div className="content">
              <UserList updateList={userListVersion} />
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Index;
