import React, { FunctionComponent, useEffect, useState } from 'react';
import { getAllUsers, responseUserToRequestUser, ResponseUserType } from '../firebaseUsers';
import { useAuthState } from '../state/AuthContext';
import UserForm from './UserForm';
import classNames from 'classnames';

interface UserListProps {
  updateList: number;
}

const UserList: FunctionComponent<UserListProps> = ({ updateList }) => {
  const { processed, user } = useAuthState();
  const [users, setUsers] = useState<Array<ResponseUserType>>([]);
  const [refreshing, setRefreshing] = useState(true);

  function refreshList() {
    setRefreshing(true);
    if (processed && user !== null) {
      getAllUsers(user)
        .then(response => {
          setUsers(response.users);
          setRefreshing(false);
        })
        .catch(error => {
          console.error(error);
          setRefreshing(false);
        });
    }
  }

  useEffect(() => {
    refreshList();
  }, [processed, updateList]);

  return (
    <div
      className={classNames({
        'users-container--list': true,
        'is-loading': refreshing,
      })}
    >
      {users.map((curUser: ResponseUserType) => (
        <div className="user-item" key={curUser.uid}>
          <UserForm callback={refreshList} formUser={responseUserToRequestUser(curUser)} />
        </div>
      ))}
    </div>
  );
};

export default UserList;
