import React, { FunctionComponent, useState } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import AntdIcon from '@ant-design/icons-react';
import { DeleteOutline, SaveFill } from '@ant-design/icons/lib';
import { addOrUpdateUser, deleteUser, RequestUserType } from '../firebaseUsers';
import { useAuthState } from '../state/AuthContext';

interface UserFormProps {
  callback: Function;
  formUser: RequestUserType | null;
}

const UserForm: FunctionComponent<UserFormProps> = ({ callback, formUser }) => {
  const { processed, user } = useAuthState();
  const [formBusy, setFormBusy] = useState(false);
  const [deleteBusy, setDeleteBusy] = useState(false);
  const [formError, setFormError] = useState('');
  const isAddForm = !formUser;

  const initialValues = (): RequestUserType => {
    if (formUser === null) {
      return {
        uid: '',
        displayName: '',
        email: '',
        password: '',
        role: 'user',
      };
    }

    return formUser;
  };

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object().shape({
      displayName: Yup.string().required(),
      email: Yup.string()
        .email()
        .required(),
      password: Yup.string().min(6),
      role: Yup.string().required(),
    }),
    onSubmit: (values: RequestUserType, { resetForm }) => {
      setFormBusy(true);
      if (processed && user !== null) {
        addOrUpdateUser(user, values)
          .then(() => {
            setFormBusy(false);
            setFormError('');
            if (isAddForm) {
              resetForm();
            }
            callback();
          })
          .catch(error => {
            console.error(error);
            setFormBusy(false);
            setFormError(error.message);
          });
      } else {
        setFormError(`Could not ${values.uid ? 'update' : 'create'} user`);
      }
    },
  });

  function onClickDeleteBtn() {
    setDeleteBusy(true);
    if (processed && user !== null && formUser && formUser.uid) {
      deleteUser(user, formUser.uid)
        .then(() => {
          setDeleteBusy(false);
          setFormError('');
          callback();
        })
        .catch(error => {
          console.error(error);
          setDeleteBusy(false);
          setFormError(error.message);
        });
    } else {
      setFormError(`Could not delete user`);
    }
  }

  return (
    <form onSubmit={formik.handleSubmit} onChange={() => setFormError('')}>
      <input type="hidden" name="uid" />
      <div className="field is-horizontal">
        <div className="field-body">
          <div className="field">
            <div className="control">
              <input
                className="input"
                name="displayName"
                type="text"
                placeholder="Gebruikersnaam"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.displayName}
              />
            </div>
            {formik.touched.displayName && formik.errors.displayName ? (
              <p className="help is-danger">{formik.errors.displayName}</p>
            ) : null}
          </div>
          <div className="field">
            <div className="control">
              <input
                className="input"
                name="email"
                type="email"
                placeholder="Email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
            </div>
            {formik.touched.email && formik.errors.email ? (
              <p className="help is-danger">{formik.errors.email}</p>
            ) : null}
          </div>
          <div className="field">
            <div className="control">
              <input
                className="input"
                name="password"
                type="password"
                placeholder="Wachtwoord"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
            </div>
            {formik.touched.password && formik.errors.password ? (
              <p className="help is-danger">{formik.errors.password}</p>
            ) : null}
          </div>
          <div className="field">
            <div className="control">
              <div className="select is-fullwidth">
                <select
                  name="role"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.role}
                >
                  <option disabled>Selecteer een rol</option>
                  <option value="admin">Administrator</option>
                  <option value="manager">Beheerder</option>
                  <option value="user">Koerier</option>
                </select>
              </div>
            </div>
            {formik.touched.role && formik.errors.role ? <p className="help is-danger">{formik.errors.role}</p> : null}
          </div>
          <div className="field is-grouped is-grouped-right">
            <div className="control flex-grow">
              <button
                disabled={formBusy || deleteBusy}
                type="submit"
                className={classNames({
                  'button is-success is-fullwidth': true,
                  'is-loading': formBusy,
                })}
              >
                {isAddForm ? 'Opslaan' : <AntdIcon type={SaveFill} />}
              </button>
            </div>
            {isAddForm ? null : (
              <div className="control flex-grow">
                <button
                  disabled={formBusy || deleteBusy}
                  type="button"
                  className={classNames({
                    'button is-danger is-fullwidth': true,
                    'is-loading': deleteBusy,
                  })}
                  onClick={onClickDeleteBtn}
                >
                  <AntdIcon type={DeleteOutline} />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="field">{formError ? <p className="has-text-danger">{formError}</p> : null}</div>
    </form>
  );
};

export default UserForm;
